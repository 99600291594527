import { AxiosInstance, AxiosResponse } from 'axios'
import {
  StockNotificationRequest,
  SubmitStockNotificationRequest,
} from '~/types/notification'

export default function (instance: AxiosInstance) {
  const base = 'api/notification'

  return {
    async subscribeStock(
      payload: SubmitStockNotificationRequest
    ): Promise<boolean> {
      const { data } = await instance.post(`/${base}/product`, {
        asposProductId: payload.productId,
        toEmailAddress: payload.email,
        sourceUrl: payload.sourceUrl,
      })

      return data?.Success ?? false
    },

    async checkStockEmail(payload: StockNotificationRequest): Promise<boolean> {
      const response: AxiosResponse = await instance.get(`/${base}/exist`, {
        params: {
          asposProductId: payload.productId,
          toEmailAddress: payload.email,
        },
      })

      return response.data?.Exist ?? false
    },
  }
}
